.club-card{
  padding-left: 10px;
  padding-right: 10px;

  .card-title {
    max-width: 70%;
  }

  &.promo {
    .card {
      background: linear-gradient(180deg, #FFE082 0%, #FFECB3 50%, #FFF8E1 100%);

      .card-category {
        color: $black;
      }

      .btn-book, .btn-more {
        background: #08562F;
      }

      .navailable {
        color: red;
        background-color: rgba(207, 196, 196, .7);
      }

      .img {
        position: relative;
        overflow: hidden;

        .exclusive {
          position: absolute;
          text-transform: uppercase;
          bottom: 95px;
          right: -45px;
          background: #E95705;
          color: white;
          font-weight: bold;
          padding: 3px 40px;
          transform: rotate(-45deg);
          transform-origin: bottom right;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.map{
  height: 100vh;
  width: 100%;
  position:fixed;
  top:0;
  z-index: 1000;
  left:100%;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  &.active{
    left:0;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  @media screen and (min-width: 992px){
    display: block;
    overflow: hidden;
    position: fixed;
    right:0;
    left:auto;
    top:0;
    width:33.33333%;
    margin-top: 130px;
    z-index: 100;
    height: calc(100vh - 130px);
  }
}

.close-map{
  background: $white;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-align: center;
  font-weight: 500;
  span{
    margin-left: auto;
  }
  @media screen and (min-width: 992px){
    display: none;
  }
}

.leaflet-container{
  height: 100%;
  width: 100%;
}

.zero-res{
  background-color: #f0f0f0;
  color: #999;
  font-weight: 400;
  font-size: 18px;
  padding: 100px 0;

}
.leaflet-popup-content-wrapper{
  padding: 0;
}
.leaflet-popup-content{
  margin:0!important;
  background: transparent;
  .card{
    height: 150px!important;
    width: 150px!important;
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    color: white;
    background-size: cover!important;
    background-blend-mode: multiply;
  }
  p{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  span.green{
    padding: 5px 10px;
    background-color: $green-400;
    margin-right: 5px;
    border-radius: 5px;
  }
}
.leaflet-popup-close-button{
  display: none!important;
}

.scrollbar-reserve {
  overflow-y: scroll;
}
