.club-card{
  .wrapper-btn-book{
    flex: 1;
    margin: 0 5px;
    p{
      margin:0 5px;
    }
  }
  .btn-book{
    background: $green-custom;
    font-size: 15px;
    flex: 1;
    padding: 0;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    .from{
      font-size: 10px;
      text-transform: none;
      display: block;
    }
  }
  .navailable{
    margin: 0;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(47,51,51,.03);
    border-radius: 5px;
    color:$red-600;
    text-align: center;
    .loading{
      height: 20px;
      display: flex;
      align-items: center;
      img{
        max-height: 20px;
        margin-right: 5px;
      }
    }
    &.green{
      color: $green-400;
    }
  }
  .card .card-header.card-header-image .card-title {
    bottom: 30px;
  }
  .card .card-header.card-header-image img{
    min-height: 220px;
  }

  .card-body{
    padding: 20px 15px 0 15px;
    .card-category{
      font-weight: 400;
      display: flex;
      align-items: flex-start;
      min-height: 2.8em;
      font-size: 15px;
      color: #0b2e13;
      span.green{
        padding: 5px 10px;
        background-color: #651716;
        margin-left: auto;
        border-radius: 5px;
        color:$white;
        font-weight: 600;
        min-width: 105px;
        text-align: center;
      }
    }
  }
  .card-header-image{
    position: relative;
    .card-note{
      font-weight: bold;
      color: $white;
      position: absolute;
      right:10px;
      top:10px;
      max-width: calc(100% - 20px);
      border-radius: 5px;
      padding: 3px 10px;
      line-height: 18px;
      z-index: 30;
    }
  }
  .btn-more{
    background: $green-400;
    color: $white;
    font-size: 15px;
    padding: 0;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    border-radius: 5px;
  }
  .card-rating{
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
      font-weight: 600;
      margin-bottom: -2px;
    }
    .star-container{
      max-width: 20px;
    }

  }
  .add-review{
    font-style: italic;
  }
}
.opacity-50{
  opacity: 0.6;
}


.orar{
  position: relative;
  .inactive{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: rgba(255,255,255,0.7);
    color:$green-400;
    font-size: 24px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
}
